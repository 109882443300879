import { useEvents } from '@api/events';
import { useGroups } from '@api/groups';
import { useResourceFiles } from '@api/resourceFiles';
import { FileLink } from '@molecules';
import CollectionCard from '@organisms/CollectionCard/CollectionCard';
import { useMemo } from 'react';

const Dashboard = () => {
  const { data: files } = useResourceFiles();
  const { data: groupData, status: groupDataStatus } = useGroups();
  const { data: events, status: eventDataStatus } = useEvents();

  const eventsLength = useMemo(() => events?.length ?? 0, [events]);
  const groupsLength = useMemo(() => groupData?.total ?? 0, [groupData]);

  return (
    <div className="my-4 mx-5 mx-xl-6">
      <h1 className="m-0">Dashboard</h1>
      <p>{"Welcome back, let's get started!"}</p>
      <div className="d-flex mt-4">
        <CollectionCard
          title="Events"
          status={groupDataStatus}
          description="Important details you'll need"
          buttonText={eventsLength === 0 ? 'Get Started' : 'Manage'}
          link="/events"
        />
        <div className="me-4" />
        <CollectionCard
          link="/themes"
          status={eventDataStatus}
          title="Themes"
          description="Create your custom textures"
          buttonText={groupsLength === 0 ? 'Get Started' : 'Manage'}
        />
      </div>
      <h3 className="mt-5">Quick links</h3>
      <div className="d-flex justify-content-start">
        {files?.map((file) => (
          <div key={file.id} className="me-3">
            <FileLink fileUrl={file.file} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default Dashboard;
