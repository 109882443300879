import { Card, LinkButton, LoadingIndicator, Thumbnail } from '@atoms';
import clsx from 'clsx';
import Image from 'next/image';

import styles from './CollectionCard.module.scss';

interface Props {
  title: string;
  status?: 'error' | 'idle' | 'loading' | 'success';
  description: string;
  buttonText: string;
  link: string;
}

const CollectionCard = ({ title, status, description, buttonText, link }: Props) => (
  <div className={clsx(styles['collection-card'], 'text-center')}>
    <Card color="light">
      <div className="p-4 p-xl-5">
        <Thumbnail>
          <Image src="/assets/img/icons/calendar.svg" alt="Calendar" layout="fill" />
        </Thumbnail>
        <h3 className="mt-3">{title}</h3>
        <p>{description}</p>
        <div className={clsx(styles['divider'], 'mb-4')} />
        <LinkButton
          name={`index-${title}`}
          color="primary"
          fullWidth
          size="lg"
          href={status === 'loading' ? '/' : link}
        >
          {status === 'loading' ? <LoadingIndicator size="sm" /> : buttonText}
        </LinkButton>
      </div>
    </Card>
  </div>
);
export default CollectionCard;
