import NavbarsLayout from '@layouts/NavbarsLayout/NavbarsLayout';
import Dashboard from '@templates/Dashboard/Dashboard';
import { NextPageWithLayout } from '@types';
import Head from 'next/head';

const Home: NextPageWithLayout = () => (
  <>
    <Head>
      <title>cAFe by ActionFace</title>
      <link rel="icon" href="/favicon.ico" />
    </Head>
    <Dashboard />;
  </>
);

Home.getLayout = NavbarsLayout;

export default Home;
