import type { AFError } from '@schemas/AFError';
import type { ResourceFile } from '@schemas/ResourceFile';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { HTTP_AUTH } from './api';

const resourceFilesUrl = '/api/cafe/v1/resource_files';

export const useResourceFiles = (): UseQueryResult<ResourceFile[], AFError> =>
  useQuery('resource-files', async () => {
    try {
      const response = await HTTP_AUTH.get<ResourceFile[]>(resourceFilesUrl);

      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: [error.message] };
        }
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  });

const resourceFileUrl = (title: string) => `/api/cafe/v1/resource_files/${title}`;

export const useResourceFile = (title: string): UseQueryResult<ResourceFile, AFError> =>
  useQuery(`resource-file-${title}`, async () => {
    try {
      const response = await HTTP_AUTH.get<ResourceFile>(resourceFileUrl(title));

      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: [error.message] };
        }
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  });
